import React from "react"

import Layout from "../components/allstar/layout"
import PageContent from "../components/allstar/resource/research-all-stars/section-hero"

const LandingPage = props => {
  return (
    <Layout
      location={props.location}
      title={"Healthcare Research All-Stars"}
      isResourceSite
    >
      <PageContent />
    </Layout>
  )
}

export default LandingPage
