import React from "react"
import {
  Flex,
  Text,
  Heading,
  Box,
  Link,
  Divider,
  useDisclosure,
} from "@chakra-ui/react"

import { Link as GatsbyLink } from "gatsby"

import { CalendarIcon } from "@chakra-ui/icons"
import { StaticImage } from "gatsby-plugin-image"

import MethodologyDrawer from "../../methodology/methodology-drawer"

import {
  ALL_STAR_PAGES_URL,
  ALL_STAR_MAIL_LINK,
} from "../../../../constants/constants"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
  },
  "inner-box": {
    margin: "0 auto",
    my: { base: "20px", md: "20px", lg: "60px" },
    width: "100%",
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: { base: "flex-start", md: "flex-start", lg: "center" },
  },
  "left-box": {
    maxWidth: { base: "100%", md: "100%", lg: "576px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    mr: { base: "0px", md: "0px", lg: "40px" },
  },
  "right-box": {
    mr: { base: "0", md: "0", lg: "104px" },
    maxWidth: { base: "100%", md: "100%", lg: "468px" },
  },
  "heading-box": {
    width: "100%",
    mt: { base: "40px", md: "40px", lg: "0px" },
  },
  heading: {
    fontSize: { base: "32px", md: "32px", lg: "40px" },
    fontWeight: 700,
    lineHeight: { base: "40px", md: "40px", lg: "48px" },
    textAlign: "center",
    letterSpacing: "0em",
  },
  greeting: {
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    fontWeight: 700,
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    letterSpacing: "0em",
    color: "#129459",
  },
  "sub-heading": {
    mt: "8px",
    fontSize: { base: "18px", md: "18px", lg: "24px" },
    lineHeight: { base: "21.48px", md: "21.48px", lg: "32px" },
    fontWeight: 700,
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2D3748",
    alignItems: "center",
  },
  link: {
    fontSize: "16px",
    color: "#3399FF",
    fontWeight: 700,
    lineHeight: "24px",
  },
  tag: {
    height: "32px",
    padding: "4px 16px 4px 16px",
    bg: "#ECC94B",
  },
  "tag-text": {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  hero: {
    mt: { base: "16px", md: "16px", lg: "40px" },
    width: { base: "144px", md: "144px", lg: "240px" },
  },
  "page-links": {
    alignItems: "center",
    flexWrap: "wrap",
  },
  seperator: {
    marginRight: "16px",
    mb: "16px",
  },
  divider: {
    marginRight: "16px",
    mb: "16px",
    h: "24px",
    borderColor: "#CCCCCD",
  },
}

const SectionHero = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box sx={styles.root}>
      <Flex sx={styles["inner-box"]}>
        <Flex sx={styles["left-box"]}>
          <Box sx={styles["heading-box"]}>
            <Heading sx={styles.greeting}>Congratulations!</Heading>
            <Heading sx={styles["sub-heading"]}>
              You’ve been named to the elite list of Healthcare Research
              All-Stars for 2024!
            </Heading>
          </Box>

          <Text sx={styles.text} mt="24px">
            For surgeons and hospitals, this honor is a recognition of the
            quality and quantity of your healthcare research published during
            2021 and 2022 and places you in the top 5% of your peers publishing
            cutting-edge healthcare research.
          </Text>
          <Text sx={styles.text} mt="24px">
            The official launch of the Research All-Stars website and public
            announcements are planned for:{" "}
            <CalendarIcon w="16px" h="24px" mx={"4px"} pb="4px" />
            <span>
              <strong>April 3, 2024</strong>
            </span>
          </Text>
          <Text sx={styles.text} mt="24px">
            To prepare for the public launch, you can review additional details
            here on the <strong>Resources</strong> website. Here you have access
            to promotional tools designed to enhance your visibility and benefit
            from your All-Star recognition. These tools include social media
            post templates, press release templates, and the option to download
            your All-Star badge.
          </Text>

          <Flex sx={styles["page-links"]} w="100%" mt="24px">
            <Link
              sx={styles.link}
              mr="16px"
              mb="16px"
              as={GatsbyLink}
              to={"/" + ALL_STAR_PAGES_URL.PRE_LAUNCH_FAQ}
            >
              FAQ
            </Link>
            <Divider sx={styles.divider} orientation="vertical" />
            <Link
              sx={styles.link}
              mr="16px"
              mb="16px"
              as={GatsbyLink}
              to={"/" + ALL_STAR_PAGES_URL.BADGES}
            >
              Badges
            </Link>
            <Divider sx={styles.divider} orientation="vertical" />
            <Link
              sx={styles.link}
              mr="16px"
              mb="16px"
              as={GatsbyLink}
              to={"/" + ALL_STAR_PAGES_URL.SOCIAL_MEDIA_TEMPLATES}
            >
              Social media templates
            </Link>
            <Divider sx={styles.divider} orientation="vertical" />
            <Link
              sx={styles.link}
              mr="16px"
              mb="16px"
              as={GatsbyLink}
              to={"/" + ALL_STAR_PAGES_URL.PRESS_RELEASE_TEMPLATE}
            >
              Press release template
            </Link>
            <Divider sx={styles.divider} orientation="vertical" />
            <Link sx={styles.link} mb="16px" onClick={onOpen}>
              Methodology
            </Link>
          </Flex>

          <Text sx={styles.text} mt="8px">
            If you have any questions or difficulty with these assets, please
            contact us at{" "}
            <Link color="#3399FF" href={ALL_STAR_MAIL_LINK}>
              Research.All-Stars@avantgardehealth.com
            </Link>
            .
          </Text>
        </Flex>
        <Box sx={styles["right-box"]}>
          <Flex flexDirection="column" alignItems="center">
            <Heading as="h2" sx={styles.heading}>
              Healthcare Research All&#x2011;Stars
            </Heading>
            <Box mt="12px" sx={styles.tag}>
              <Text sx={styles["tag-text"]}>Resources Site</Text>
            </Box>
            <Box sx={styles.hero}>
              <StaticImage
                src="../../../images/allstar/Healthcare Research All-Stars 2024 - Logo.png"
                alt="Healthcare Research All-Stars Logo"
                placeholder="blurred"
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
      <MethodologyDrawer isOpen={isOpen} onClose={onClose} />
    </Box>
  )
}

export default SectionHero
